import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'animate.css';
import SiteLinks from './SiteLinks/SiteLinks.js';
import HistoricalChart from './HistoricalChart.js';
import Retrospective from './Retrospective.js';
import Blip from './images/blip.png';
import Rocket from './images/rocket.png';
import Logo from './images/logo-mark-white.png';
import Logo2 from './images/ecash-circle.png';
import Logo3 from './images/bch-circle.png';
import Chart from './Chart.js';
import CountUp from 'react-countup';

const App = () => {
  const [xec, setXec] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [bch, setBch] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [ratio, setRatio] = useState([0, 0]);
  const [blockchair, setBlockchair] = useState([0, 0, 0, 0]);
  const [ecashtransactions, seteCashTransactions] = useState(0);
  const [gains, setGains] = useState([0, 1]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [xecChartData, setxecChartData] = useState([
    { time: '2019-04-11', value: 2 },
    { time: '2019-04-12', value: 2 },
    { time: '2019-04-13', value: 2 },
    { time: '2019-04-14', value: 2 },
    { time: '2019-04-15', value: 2 },
    { time: '2019-04-16', value: 2 },
    { time: '2019-04-17', value: 2 },
    { time: '2019-04-18', value: 2 },
  ]);
  const [bchChartData, setbchChartData] = useState([]);

  const updatePrice = () => {
    const priceApi =
      'https://api.coingecko.com/api/v3/simple/price?ids=ecash%2Cbitcoin-cash&vs_currencies=usd&include_market_cap=true&include_24hr_vol=true&include_24hr_change=true';
    const bitcoincash = 'bitcoin-cash';
    axios.get(priceApi).then((response) => {
      if (response.data) {
        setXec([
          response.data.ecash.usd,
          response.data.ecash.usd_24h_change,
          response.data.ecash.usd_market_cap.toFixed(),
          response.data.ecash.usd_24h_vol,
        ]);
        setBch([
          response.data[bitcoincash].usd,
          response.data[bitcoincash].usd_24h_change,
          response.data[bitcoincash].usd_market_cap.toFixed(),
          response.data[bitcoincash].usd_24h_vol,
        ]);
        setRatio([
          (
            (response.data.ecash.usd * 1000000) /
            response.data[bitcoincash].usd
          ).toFixed(2),
          (
            (response.data.ecash.usd_market_cap /
              response.data[bitcoincash].usd_market_cap) *
            100
          ).toFixed(2),
        ]);
        setLoading(false);

        axios
          .get('https://cashening.com/api/marketcaps/XEC+BCH')
          .then((response2) => {
            if (response2.data) {
              let xecdata = response2.data.map(({ date: time, XEC }) => ({
                time,
                XEC,
              }));
              for (let i = 0; i < xecdata.length; i++) {
                let obj = xecdata[i];
                obj['value'] = (xecdata[i].XEC / 1000000000).toFixed(2);
              }
              let XECdivided = xecdata
                .map(({ time, value }) => ({
                  time,
                  value,
                }))
                .reverse();
              // XECdivided[XECdivided.length - 1].value = (
              //   response.data.ecash.usd_market_cap / 1000000000
              // ).toFixed(2);
              //// Get BCH Data
              let bchdata = response2.data.map(({ date: time, BCH }) => ({
                time,
                BCH,
              }));
              for (let i = 0; i < bchdata.length; i++) {
                let obj = bchdata[i];
                obj['value'] = (bchdata[i].BCH / 1000000000).toFixed(2);
              }
              let BCHdivided = bchdata
                .map(({ time, value }) => ({
                  time,
                  value,
                }))
                .reverse();
              // BCHdivided[BCHdivided.length - 1].value = (
              //   response.data[bitcoincash].usd_market_cap / 1000000000
              // ).toFixed(2);
              setxecChartData(XECdivided);
              setbchChartData(BCHdivided);
              // console.log(XECdivided);
              setLoading2(false);
            }
          });
      }
    });
  };

  const updateTransactions = () => {
    const blockchairApi = 'https://api.blockchair.com/stats';
    const bitcoincash = 'bitcoin-cash';
    axios.get(blockchairApi).then((response) => {
      if (response.data) {
        setBlockchair([
          response.data.data.ecash.data.market_dominance_percentage,
          response.data.data.ecash.data.transactions_24h,
          response.data.data[bitcoincash].data.market_dominance_percentage,
          response.data.data[bitcoincash].data.transactions_24h,
        ]);
      }
    });
    axios.get('https://cashening.com/api/transactions/XEC').then((response) => {
      if (response.data) {
        seteCashTransactions(response.data.transactions);
      }
    });
  };

  useEffect(() => {
    updatePrice();
    updateTransactions();
    const interval = setInterval(() => {
      updatePrice();
    }, 40000);
    return () => clearInterval(interval);
  }, []);

  const xecForkDayPrice = 0.0000104172;
  const bchForkDayPrice = 363.58;

  var xecforkdate = new Date('11/15/2020');
  var bchforkdate = new Date('08/01/2017');
  var today = new Date();
  var xectimedifference = today - xecforkdate.getTime();
  var xecMonthsPast = xectimedifference / (1000 * 3600 * 24 * 30);
  var bchtimedifference = today - bchforkdate.getTime();
  var bchMonthsPast = bchtimedifference / (1000 * 3600 * 24 * 30);

  const calcGains = (current_price, chain) => {
    if (chain === 'xec') {
      const pricedivide = current_price / xecForkDayPrice;
      const exponent = 1 / xecMonthsPast;
      const exponent_calculation = (pricedivide ** exponent - 1) * 100;
      return (
        <>
          {exponent_calculation > 0 ? '+' : null}
          {exponent_calculation.toFixed(2)}
        </>
      );
    }
    if (chain === 'bch') {
      const pricedivide = current_price / bchForkDayPrice;
      const exponent = 1 / bchMonthsPast;
      const exponent_calculation = (pricedivide ** exponent - 1) * 100;
      return (
        <>
          {exponent_calculation > 0 ? '+' : null}
          {exponent_calculation.toFixed(2)}
        </>
      );
    }
  };

  useEffect(() => {
    setGains([calcGains(xec[0], 'xec'), calcGains(bch[0], 'bch')]);
  }, [xec, bch]);

  return (
    <div className="wrapper">
      <a className="banner" href="https://avalanche.cash/">
        eCash is raising the stakes. Click here to learn more.
      </a>
      <div className="header-ctn">
        <div className="header animate__animated animate__flipInY">
          <h1 className="glitch" data-text="The Cashening">
            The Cashening
          </h1>
          <img src={Blip} alt="ecash" className="blip" />
          <h5>
            It’s only a matter of time{' '}
            <img src={Rocket} alt="ecash" className="rocket" />
          </h5>
        </div>
      </div>

      <div className="price-info-ctn animate__animated animate__fadeInUp">
        <div className="price-block-ctn">
          <img src={Logo2} alt="ecash" className="priceblock-icon" />
          <div className="price-block">
            {loading ? (
              <>
                <div className="loading"></div>
                <br />
                <div className="loading"></div>
              </>
            ) : (
              <>
                <span>XEC</span>: ${xec[0].toFixed(6)}
                <br />
                <div className="priceblock-percent">
                  <span
                    style={xec[1] >= 0 ? null : { color: 'rgb(239,68,68)' }}
                  >
                    {xec[1].toFixed(2)}%
                  </span>{' '}
                  24h price
                </div>
              </>
            )}
          </div>
        </div>

        <div className="price-block-ctn">
          <img src={Logo3} alt="ecash" className="priceblock-icon" />
          <div className="price-block">
            {loading ? (
              <>
                <div className="loading"></div>
                <br />
                <div className="loading"></div>
              </>
            ) : (
              <>
                <span>BCH</span>: ${bch[0].toFixed(2)}
                <br />
                <div className="priceblock-percent">
                  <span
                    style={bch[1] >= 0 ? null : { color: 'rgb(239,68,68)' }}
                  >
                    {bch[1].toFixed(2)}%
                  </span>{' '}
                  24h price
                </div>
              </>
            )}
          </div>
        </div>

        <div className="price-block-ctn">
          <div className="price-block">
            {loading ? (
              <>
                <div className="loading"></div>
                <br />
                <div className="loading"></div>
              </>
            ) : (
              <>
                <span>Price Ratio</span>: {ratio[0]}
                <br />
                <div className="priceblock-percent">based on 1M XEC</div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="bar-ctn animate__animated animate__fadeInUp animate-delay">
        <h3>Market Cap Ratio</h3>
        <div className="bar">
          <div className="flip-indicator fi-1"></div>
          <div className="flip-indicator fi-2"></div>
          <div className="flip-indicator fi-3"></div>
          <div className="flip-indicator fi-4"></div>
          <div className="fi-5"></div>
          {/* <div
            className="flip-indicator-box-2"
            style={{
              left: `calc(${(ratio[1] / 100) * 80}%)`,
              visibility: ratio[1] <= 5 ? "hidden" : null,
            }}
          >
            XEC Market Cap: ${(xec[2] / 1000000000).toFixed(2)}B
          </div>
          <div className="flip-indicator-box"></div> */}
          <div
            className="inner-bar"
            style={{
              width: ratio[1] + '%',
            }}
          >
            <div className="percentage-label">
              <CountUp
                start={0}
                end={ratio[1]}
                duration={3}
                decimals={2}
                decimal="."
                suffix="%"
              ></CountUp>
            </div>
            <img src={Logo} alt="ecash" className="ecash-icon" />
          </div>
        </div>
        <div className="bar-info-box">
          XEC: <span>${(xec[2] / 1000000000).toFixed(2)}B</span> / BCH:{' '}
          <span>${(bch[2] / 1000000000).toFixed(2)}B</span>
        </div>
      </div>
      <HistoricalChart
        xecChartData={xecChartData}
        bchChartData={bchChartData}
        loading={loading2}
      />
      <Retrospective />
      <Chart
        xec={xec}
        bch={bch}
        gains={gains}
        blockchair={blockchair}
        ecashtransactions={ecashtransactions}
      />
      <SiteLinks />
    </div>
  );
};

export default App;
